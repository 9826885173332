import React from "react"
import { Router } from "@reach/router"
import { Layout, PrivateRoute, Report } from "../components"

const ReportPage = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/report/" component={Report} />
    </Router>
  </Layout>
)

export default ReportPage
